import { Link } from 'react-router-dom';
import React, { useEffect } from "react";

import  '../../../assets/css/animatedcounter.css'
import './style.css';

import backgroundImage from '../../../assets/img/c341cb31-cfe6-46a3-b5ba-820ef0f9fd29.png';
import foregroundImage from '../../../assets/img/95349943-a8e4-4253-8a57-8c60a9d93192.png';
import rotatingImage from '../../../assets/img/312da22f-a914-4bc9-8cb2-35b461392147.png';

const CounterUp = () => {
    useEffect(() => {
        (function () {
            var counters = document.getElementsByClassName("animatedcounter__number");
            var vals = Array.from(counters).map(function (x) {
                return Number(x.innerHTML.replace(/[^0-9.]/gi, ""));
            });
            counters = Array.from(counters);
            counters.forEach(function (el) {
                el.innerHTML = "0";
                el.counter = 0;
            });
            var update = function update() {
                counters.forEach(function (el, i) {
                    if (el.innerHTML.length < 2) {
                        el.counter += 1;
                        el.innerHTML = Math.min(vals[i], el.counter);
                    } else if (el.innerHTML.length === 2 && el.innerHTML.length < 3) {
                        el.counter += 5;
                        el.innerHTML = Math.min(vals[i], el.counter);
                    } else {
                        el.counter += 100;
                        el.innerHTML = Math.min(vals[i], el.counter).toLocaleString();
                    }
                });
                requestAnimationFrame(update);
            };
            var elem = document.querySelector(".animatedcounter");
            if (elem) {
                var opts = { rootMargin: "".concat(elem.clientHeight, "px") };
                var callback = function callback(entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.intersectionRatio > 0 || (entry.intersectionRatio === 0 && entry.intersectionRect.top > 0)) {
                            observer.disconnect();
                            update();
                        }
                    });
                };
                var observer = new IntersectionObserver(callback, opts);
                observer.observe(elem);
            }
        })();

    }, []);
    return (
      <>
        <div id="b67da1cd-5e3d-468f-85c5-e0de640903a9" className="containerv2 containerv2--middle containerv2--gutter">
            <div className="containerv2__regions" style={{maxWidth: '1386px'}}>
                <div className="containerv2__region containerv2__region--6" data-region>

                    <div id="f62ee14a-915f-4303-9b39-37bf433a3914" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <h2 className="text-center text-md-left">Your All-in-One Cannabis Ordering and Tracking Platform</h2>
                        </div>
                    </div>
                    <div id="b8c3f9a3-c14a-4918-afdb-5c9804bc5b17" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <p className="text-center text-md-left">Premium Quality<br />Full Traceablity<br />On Time &amp; No Delivery Fees<br />Choose From A
                                Variety of National and Local Brands Your Patients Love</p>
                        </div>
                    </div>
                    <div id="SH2ef102d4-d911-485f-85d2-806f62ffe4b6" className="containerv2 containerv2--counter-bar containerv2--w-full containerv2--inl containerv2--inl-auto containerv2--h-left">
                        <div className="containerv2__regions " data-container-regions>
                            <div className="containerv2__region containerv2__region--6 justify-content-sm-center" data-region>
                                <div id="3b1c2ab8-7b78-47a1-8f2f-65611177f6e9" className="animatedcounter">
                                    <div className="animatedcounter__container">
                                        <div className="animatedcounter__number">26</div>
                                        <div className="animatedcounter__label">Brands</div>
                                    </div>
                                </div>
                                <div id="8217170a-6bc4-48d4-a6a4-776ff75bfb47" className="text-content">
                                    <div className="text-content__content" data-wysiwyg>
                                        <p><span>+</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="containerv2__region containerv2__region--6 justify-content-sm-center" data-region>
                                <div id="3aa53c53-4714-46ce-b5c7-c9f5b64096d2" className="animatedcounter">
                                    <div className="animatedcounter__container">
                                        <div className="animatedcounter__number">700</div>
                                        <div className="animatedcounter__label">SKU&#39;s</div>
                                    </div>
                                </div>
                                <div id="af8fc40a-312c-4395-b978-e83ed8110554" className="text-content">
                                    <div className="text-content__content" data-wysiwyg>
                                        <p><span >+</span></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
                        </div>
                    </div>
                    
                </div>
                <div className="containerv2__region containerv2__region--6" data-region>
                    
                    <div id="d1d71b06-949f-4fee-a11c-3a8191164b62" className="image image--back">
                        <img className="image__img" src={backgroundImage} alt="background" loading="lazy" />
                    </div>
                    <div id="b2f1b47f-e4cb-4b8d-8eb5-0c1111ff7e54" className="image image--square image--front-square">
                        <img className="image__img" src={foregroundImage} alt="foreground" loading="lazy" />
                    </div>

                    <div id="aeb36090-d0e8-4ab2-bbc8-1148a242f07a" className="link-region link-region--rotate-link">
                        <Link className="link-region__link" to="/contact-us/" target="" aria-label="Get In Touch, contact us" data-region>
                            <div id="648084c7-9934-41e8-b3b5-1723e4cd6643" className="image">
                                <img className="image__img" src={rotatingImage} alt="Get In Touch" loading="lazy" />
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
            <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
            </div>
        </div>
      </>
    )
}
 
export default CounterUp
