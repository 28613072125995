import { useEffect} from 'react';
import './style.css';

const IFrameVideo = (props) => {
    let iFrameSrc = props.iFrameSrc;
    let h1Tag = props.h1Tag;
    let pTag = props.pTag;
    useEffect(() => {
        
       
       
    }, []);
    return (
     <>
     
                    <div className="video__container">
                        <div className="video__player iFrame-video-container">
                            <iframe className="video__file " title="Distribution Background Video"
                                src={iFrameSrc}
                                 allow="autoplay; fullscreen"
                                allowFullScreen=""></iframe>
                        </div>

                    </div>
                
      </>
    )
}
 
export default IFrameVideo
