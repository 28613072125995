import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import FloatingContact from '../../layout/FloatingContact';

import FullScreenBackgroundVideo from '../../components/FullScreenBackgroundVideo';
import ListGrid from './ListGrid';
import CallToAction from './CallToAction';

export function DistributionPage() {
  return (
    <>
        <Header/>
        
        <div className="App">
            <FullScreenBackgroundVideo h1Tag="DISTRIBUTION" pTag="where you need it when you need it" iFrameSrc="https://player.vimeo.com/video/751716985?h=63fe4205c7&amp;autoplay=1&amp;muted=1&amp;loop=1"/>
            <ListGrid />
            <CallToAction />
            <FloatingContact />
        </div>
        
        <Footer/>
    </>
  );
}
