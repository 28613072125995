
(function () {
    var navs = document.querySelectorAll(".navigation");
    navs.forEach(function (nav) {
        var btn = nav.querySelector(".navigation__menu");
        var close = nav.querySelector(".navigation__close");
        var menu = nav.querySelector(".navigation__container");
        if (btn) {
            btn.addEventListener("click", toggleNav.bind(btn, menu));
        }
        if (close) {
            close.addEventListener("click", closeNav.bind(close, menu));
        }
    });
    function openNav(menu) {
        if (menu) {
            document.body.classList.add("header-side-nav-open");
            window.a11y.trapFocus(menu);
        }
    }
    function closeNav(menu) {
        if (menu) {
            document.body.classList.remove("header-side-nav-open");
            window.a11y.untrapFocus();
        }
    }
    function toggleNav(menu) {
        if (menu) {
            if (document.body.classList.contains("header-side-nav-open")) {
                closeNav(menu);
            } else {
                openNav(menu);
            }
        }
    }
})();
