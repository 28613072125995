import { useEffect} from 'react';
import './style.css';

const FullScreenBackgroundVideo = (props) => {
    let iFrameSrc = props.iFrameSrc;
    let h1Tag = props.h1Tag;
    let pTag = props.pTag;
    useEffect(() => {
        
       
            var videoPlayer = document.querySelector('.containerv2--bg-slide-left');
            if (videoPlayer) {
                videoPlayer.classList.add('containerv2--bg-sliding-left');
            }
       
    }, []);
    return (
      <>
        <div id="SH996f5d02-6abf-4ea5-9496-89c692295343" className="containerv2 containerv2--bg-slide-left containerv2--middle containerv2--masthead c-tx c-tx--white">
            <div className="containerv2__regions" data-container-regions>
                <div className="containerv2__region containerv2__region--12" data-region>

                    <div id="107ed156-146d-4c5f-adf5-a26bfb75af36" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <p>{pTag}</p>
                        </div>
                    </div>
                    <div id="e55a4cc8-606c-4fc9-b514-1a4437933e84" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <h1>{h1Tag}</h1>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div className="containerv2__background" aria-hidden="true" data-container-background data-region>

                <div id="b7dc768e-0033-493c-bccb-1f2c7a00d2a2" className="video ">
                    <div className="video__container ">
                        <div className="video__player">
                            <iframe className="video__file " title="Distribution Background Video"
                                src={iFrameSrc}
                                 allow="autoplay; fullscreen"
                                allowFullScreen=""></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
 
export default FullScreenBackgroundVideo
