import heroBackgroundImage from '../../../assets/img/GreenBanner.png'
import './style.css';

const Hero = () => {
    return (
      <>
        <div id="3b033ade-49b9-4eda-a7fc-36e1a23beae4"
            className="containerv2 containerv2--inl containerv2--inl-auto containerv2--middle" style={{padding: '75px 0'}}>
            <div className="containerv2__regions" data-container-regions style={{'maxWidth': '1386px'}}>
                <div className="containerv2__region containerv2__region--6" data-region>

                    <div id="ea4bb855-7a88-4bb1-85e0-0e9f57b0159f" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <h1><span className="font-fam--primary">THE LEADER IN CANNABIS MANUFACTURING &amp; DISTRIBUTION</span></h1>
                        </div>
                    </div>
                </div>
                <div className="containerv2__region containerv2__region--6" data-region>
                    
                    <div id="S3a444c92-2880-4eec-9c11-83dbdd11f03f" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <p><span>Stash House Distribution™ manufactures and distributes every cannabis finished form product offered by our dispensary customers. We do this for the top national brands in the country, including Wana, Cheeba Chews, Kiva, 1906, Packs, Ripple, Rove, the Clear, Dixie, Stone Road, and many more. Our technology platform places stress-free dispensary ordering and brand transparency at our customers' fingertips. This technology platform, coupled with our deep domain experience in distribution and sales, separates Stash House from the rest of the market by placing everything under one roof.</span></p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="containerv2__background" aria-hidden="true" data-container-background data-region>

                <div id="278b7852-107d-4357-9075-35d20bfe8b8e" className="image image--full image--background">

                        <img className="image__img"
                            src={heroBackgroundImage}
                            alt="stash house hero" loading="lazy" />
                    
                </div>
            </div>
        </div>
      </>
    )
}
 
export default Hero
