import { useEffect} from 'react';
import '../../../assets/css/listgrid.css';
import './style.css';
import logo1906 from '../../../assets/img/0f3c9167-2350-4799-9059-0eda6db884e4.png';
import {Brands} from './Brands';

const NationalPartners = () => {
        useEffect(() => {
           
        return () => {
           
        }
    }, []);
    return (
        <>
           <div id="a3fddbeb-2a27-4bb1-b929-0e3fd2f6d0db" className="containerv2 containerv2--w-full containerv2--h-middle" style={{paddingTop: '72px'}}>
                <div className="containerv2__regions" data-container-regions>
                    <div className="containerv2__region containerv2__region--12" data-region>

                        <div id="SH52efb4c9-1a47-4dd4-ae90-e6adaaf1a281" className="text-content">
                            <div className="text-content__content" data-wysiwyg>
                                <h1>Our National Partners</h1>
                            </div>
                        </div>
                        <div id="c7e5caf8-fb6e-48ef-823f-07fd8154bfb3" className="containerv2 containerv2--w-full c-bg">
                            <div className="containerv2__regions" data-container-regions>
                                <div className="containerv2__region containerv2__region--12" data-region>
                                    

                                    <div id="0c840348-8267-49f7-8a72-84899899c80d"
                                        className="listgrid listgrid--align-left" data-carousel-items="1">
                                        <ul className="listgrid__list">
                                           
                                            {Brands.map((brand) => {
                                                return(
                                                
                                                <li className="listgrid__item" key={brand.key}>
                                                    <div className="listgrid__image image image--full">
                                                        <img src={brand.logo} className="image__img" alt={brand.name} />
                                                    </div>
                                                </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}
 
export default NationalPartners
