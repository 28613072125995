import './style.css';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

export function DisclaimerPage() {
    
    return (
        <>
            <Header/>
            
            <div className="App">
                <div id="SH240a2413-d9e4-4ea4-b814-592a0c47aa6d" className="text-content">
                    <div className="text-content__content" data-wysiwyg>
                        <h1 className="text-center" >Disclaimer</h1>
                        <p>All text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software and protected by United States and international copyright laws. The developer/builder/owner reserves the right in its sole discretion to make changes or modifications to maps, plans, specifications, materials, features and colors without notice.</p>
                        
                        <p>No portion of this website -- inclusive of, but not limited to, design elements, photos, coding, and look & feel -- may be used or reproduced by any means without the express written consent of stashhousedistro.com. Photos, images and other 3rd party licensing provided by the website owner is licensed to this domain only and cannot be reproduced in any form without additional licensing fees.</p>
                        <p>All trademarks herein are the property of their respective owners who have all the rights therein. In addition, graphics, logos, page headers, button icons, scripts are trademarks. No trademarks or service marks may be used or reproduced without the express written consent of their respective owners.</p>
                        
                        
                    </div>
                </div>
            </div>
            
            <Footer/>
        </>
    );
}
