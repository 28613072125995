import logo1906 from "../../../assets/img/9109cb0a-09f2-4872-ae3e-9228b57eacb3.png";
import logoCheebaChews from "../../../assets/img/9d8341ef-1532-44ef-8557-048fabd39c9f.png";
import logoCrumble from "../../../assets/img/5a6de774-b904-4536-94e8-0b8c167a4769.png";
import logoDabba from "../../../assets/img/2c7ad57a-18cc-4614-aa02-f0447f95cfe1.png";
import logoDixie from "../../../assets/img/2e4b0cd6-caa2-4a1f-9f6a-33cdf962e8fb.png";
import logoDrool from "../../../assets/img/5e1fe4e4-739b-46e7-bc95-19ee04156a78.png";
import logoGreenHornet from "../../../assets/img/e516889f-d64f-4991-96db-c8f40557134e.png";
import logoKiva from "../../../assets/img/a6886076-9bef-48ab-822a-35fdf50e18e0.png";
import logoKokoNuggz from "../../../assets/img/4ad1a557-b592-416e-81e3-a8b5534403c4.png";
import logoMarysMedicinal from "../../../assets/img/cb01e43b-b091-4c43-aaee-06b357c28419.png";
import logoMobius from "../../../assets/img/9397dab6-a6aa-4c82-ac6a-a13e25da20e4.png";
import logoMountainHighSuckers from "../../../assets/img/f3add97d-940a-427f-afea-fb91698e0bc4.png";
import logoPackwoods from "../../../assets/img/626d66a4-dc84-4ab9-b9e2-4c0845eba0ce.png";
import logoRove from "../../../assets/img/0c10906d-dc1d-42ef-b804-4ce78e3886b9.png";
import logoStoneRoad from "../../../assets/img/fa7294fc-fbd2-4d97-ba42-fb3795b63292.png";
import logoTheClear from "../../../assets/img/cbcfb223-4a2f-4cbc-bccd-6e087094d9ca.png";
import logoTwax from "../../../assets/img/5fa90aa7-0a6a-4809-8e1e-6a1e0137115b.png";
import logoWana from "../../../assets/img/850e73c7-5814-4552-8fb9-eb33cd6b9757.png";
import logoWynk from "../../../assets/img/bd03734b-8e17-4352-b6d9-47e133890901.png";
import logoLeune from "../../../assets/img/0f3c9167-2350-4799-9059-0eda6db884e4.png";

export const Brands = [
    {
        key: 1,
        name: "1906",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logo1906
    },
    {
        key: 2,
        name: "Cheeba Chews",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoCheebaChews
    },
    {
        key: 3,
        name: "Crumble",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoCrumble
    },
    {
        key: 4,
        name: "Dabba",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoDabba
    },
    {
        key: 5,
        name: "Dixie",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoDixie
    },
    {
        key: 6,
        name: "Drool",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoDrool
    },
    {
        key: 7,
        name: "Green Hornet",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoGreenHornet
    },
    {
        key: 8,
        name: "Kiva",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoKiva
    },
    {
        key: 9,
        name: "Koko Nuggz",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoKokoNuggz
    },
    {
        key: 10,
        name: "Mary's Medicinals",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoMarysMedicinal
    },
    {
        key: 11,
        name: "Mobius",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoMobius
    },
    {
        key: 12,
        name: "Mountain High Suckers",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoMountainHighSuckers
    },
    {
        key: 13,
        name: "Packwoods",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoPackwoods
    },
    {
        key: 14,
        name: "Rove",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoRove
    },
    {
        key: 15,
        name: "Stone Road",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoStoneRoad
    },
    {
        key: 16,
        name: "The Clear",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoTheClear
    },
    {
        key: 17,
        name: "Twax",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoTwax
    },
    {
        key: 18,
        name: "Wana",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoWana
    },
    {
        key: 19,
        name: "Wynk",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoWynk
    },
    {
        key: 20,
        name: "Leune",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
        logo: logoLeune
    },
]