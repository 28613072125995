import axios from "axios";
import React, { useEffect, useState, useRef} from "react";
import './style.css';
import { get } from "react-hook-form";

const BrandTiles = (props) => {
    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const companyState = props.companyState;
    
    const baseURL = 'https://ok.stashhousedistro.com';
    const baseImageURL = 'https://'+companyState.toLowerCase()+'.stashhousedistro.com';
    useEffect(() => {
        async function getBrands(data) {
            setLoading(true);
           
            try {
                const response = await axios.get(`${baseURL}/admin/api/v2/client/get-state-brands.asp`, {
                    params: {
                        state: companyState,
                    }
                });
                if(response.data.Success){
                    console.log(response.data.brands);
                    setBrands(response.data.brands);
                }
                //alert(brands.length);
                //names.map(function(name, index){
            } catch (error){

            }
        }
       getBrands();
   
    }, [companyState]);

    return (
     <>
       
                    <div className="brand-tiles">
                    {brands.map(function(brand, index){
                        return <>
                            <div className="brand-tile" data-Index={index}>
                                <div className="img-container">
                                    <img src={baseImageURL + brand.HeroImageURL} alt="" />
                                </div>
                               
                            </div>
                        </>
                    })}
                    </div>
              
                      
      </>
    )
}
 
export default BrandTiles
