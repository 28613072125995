import './style.css';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { Link } from 'react-router-dom';

export function DoNotSellMyInfoPage() {
    
    return (
        <>
            <Header/>
            
            <div className="App">
                <div id="SH240a2413-d9e4-4ea4-b814-592a0c47aa6d" className="text-content">
                    <div className="text-content__content" data-wysiwyg>
                        <h1 className="text-center;" >We do not sell your information</h1>
                        <p><b>We are required to word the link as “Do Not Sell My Personal Information” per the California Consumer Privacy Act (CCPA).</b></p>
                        
                        <p>We acknowledge that California consumers have the right to opt-out of the sale of their personal information. www.stashhousedistro.com does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide information or services requested, when we have your permission, or under the following circumstances:

</p><p>We disclose necessary information (personal identifiers, professional information, etc) to trusted partners/vendors who work on behalf of or with www.stashhousedistro.com. These companies may use your personal information to provide additional information to you regarding pricing, availability, and other updates related to requested information as it updates or for online, time-saving services.

</p><p>We also respond to subpoenas, court orders, or legal processes, or to establish or exercise our legal rights or defend against legal claims. We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of website’s terms of use, or as otherwise required by law.

</p><p>www.stashhousedistro.com may transfer information about you if we are acquired by or merged with another company. In this event, your information may become subject to a different privacy policy. There may also be times when we would share your information with partners or vendors outside of what has been explicitly defined. In those cases, the third-party would provide you with the opportunity to opt-out.

</p><p>If you would like to opt-out of the sale of your personal information or if you have questions, please email datarights@stashhousedistro.com with the details of your request.

</p><p>* Please Note: The information provided to this email address will be used to help validate your request and/or to communicate with you throughout the process.

</p><p>California consumers may utilize an authorized agent to submit a request to opt-out of the sale of their personal information on their behalf. All Authorized Agents operating on the behalf of a consumer must provide a written (and signed) document establishing their authority to submit requests and must be able to validate their own identity.

</p><p>California consumers have the right not to receive discriminatory treatment for exercising of the privacy rights conferred by the California Consumer Privacy Act (CCPA).

</p><p>Additional Information regarding your rights under the CCPA can be found in our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
                    </div>
                </div>
            </div>
            
            <Footer/>
        </>
    );
}
