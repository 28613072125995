import React, { useRef, } from "react";
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import FloatingContact from '../../layout/FloatingContact';
import img1 from '../../assets/img/bf244745-8b7d-475f-b192-23ef0b8acc2d.jpg';
import img2 from '../../assets/img/89df3b85-1ca7-48ce-845f-3a2f13dd1027.jpg';

import IFrameVideo from '../../components/IFrameVideo';
import YTFullScreenBackgroundVideo from '../../components/YTFullScreenBackgroundVideo';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';


import  '../../assets/css/gallery.css'
import g1Slide1 from '../../assets/img/4ca4ce95-23a6-4528-87fb-fbdd6bc3c6f3.jpg';
import g1Slide2 from '../../assets/img/338e3ca9-2516-481c-b46f-f400bfae3250.jpg';
import g1Slide3 from '../../assets/img/7884d292-d33b-4401-8a5c-97ca33a87b9c.jpg';
import g2Slide1 from '../../assets/img/222dfae5-e16d-4ccf-8838-01e38bc0f367.jpg';
import g2Slide2 from '../../assets/img/aec6f1e8-1b32-4450-a257-49325b8bf84a.jpg';
import g2Slide3 from '../../assets/img/02c34f3c-bdb7-4972-b79d-aa7c188ad16a.png';


import './style.css';

export function AboutPage() {
    const dispensaryGallery = useRef(null);
    const brandsGallery = useRef(null);
   
    const properties = {
        duration: 5000,
        autoplay: true,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: "ease",
        //indicators: (i) => <div className="indicator">{i + 1}</div>
    };
    const divStyle = {
        display: 'flex',
        aspectRatio: '5/3',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: 'auto'
    }
    const dispensarySlideImages = [
        {
          url: g1Slide1,
          caption: 'Slide 1'
        },
        {
          url: g1Slide2,
          caption: 'Slide 2'
        },
        {
          url: g1Slide3,
          caption: 'Slide 3'
        },
    ];
    const brandsSlideImages = [
        {
          url: g2Slide1,
          caption: 'Slide 1'
        },
        {
          url: g2Slide2,
          caption: 'Slide 2'
        },
        {
          url: g2Slide3,
          caption: 'Slide 3'
        },
    ];
  
    function g1back(){
        dispensaryGallery.current.goBack();
       
    }
    function g1next(){
        dispensaryGallery.current.goNext();
    }

    function g2back(){
        dispensaryGallery.current.goBack();
       
    }
    function g2next(){
        dispensaryGallery.current.goNext();
    }
   return(
        <>
            <Header/>
        
            <div className="App">
                <YTFullScreenBackgroundVideo h1Tag="" pTag="" videoID="sWFQwA6EYMA"/>
            
                <div id="SH9aeb6a21-4542-4c55-8e15-bd35cfc2b20a" className="containerv2 containerv2--no-text-margin containerv2--gutter-8d containerv2--gutter">
                    <div className="containerv2__regions" style={{'maxWidth':'1386px;'}}>
                        <div className="containerv2__region containerv2__region--6" data-region="">

                            <div id="d79b0ed5-b462-49d5-aae8-91397de0fba3" className="text-content text-content--set">
                                <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                    <p>PIONEERING RESPONSIBILITY</p>
                                    <h1 className="text-center text-md-left">The Art of Cannabis Manufacturing and Distribution</h1>
                                </div>
                            </div>
                            <div id="5e6c4037-1c82-41e3-a33d-b77708a35365" className="containerv2 containerv2--w-full containerv2--h-right"
                                >
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">

                                        <div id="0ba663bb-150a-40f5-a64d-d0086cfb13cc" className="containerv2 containerv2--w-full" >
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--12" data-region="">

                                                    <div id="SH76bf3fda-c21a-48cd-b62d-9a44c99148bb" className="image image--5-4">

                                                        <img className="image__img" alt="" src="https://u2p2r8i5.stackpathcdn.com/img/thumbnail.aspx?p=/common/uploads/client/740/media/82f68f86-20a1-4508-9a7a-9dcb15bb612f.jpg&amp;q=71&amp;x=50&amp;y=50&amp;w=480&amp;h=640"/>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                        </div>
                                    </div>

                                </div>
                                <div className="containerv2__background" aria-hidden="true" data-container-background="" data-region="">
                                </div>
                            </div>
                        </div>
                        <div className="containerv2__region containerv2__region--6" data-region="">

                            <div id="09ca8f76-7421-4d53-9e6c-380443c645bf" className="containerv2 contianerv2--region-gutter containerv2--w-full containerv2--h-left containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">

                                        <div id="007164cf-b61e-4ada-a1c7-0e8169c5ea72" className="containerv2 containerv2--w-full" >
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--12" data-region="">

                                                    <div id="SH21004d03-d174-4f54-b5c7-8dbff5d10528" className="image image--5-4">

                                                        <img className="image__img" alt="" src="https://u2p2r8i5.stackpathcdn.com/img/thumbnail.aspx?p=/common/uploads/client/740/media/61f69729-7f42-4f85-91fc-c1f4cba7645f.jpg&amp;q=71&amp;x=50&amp;y=50&amp;w=800&amp;h=864"/>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                        </div>

                                        <div id="SH5dcbe953-aba5-4ecd-ba66-dfcd1505c4b9" className="text-content text-content--set">
                                            <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                <p>We're in the business of making the cannabis experience simpler for everyone</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    </div>
                
                </div>
                <div id="SH1861d072-2826-4a66-a73e-70d1904fcf16" className="containerv2 containerv2--gutter c-bg c-bg--dark"
                    style={{'paddingTop': '75px','paddingBottom': '50px'}}>
                    <div className="containerv2__regions" data-container-regions="">
                        <div className="containerv2__region containerv2__region--12" data-region="">

                            <div id="SH722da5e9-4273-4f96-bd7d-2746d57642d6" className="containerv2 containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--4" data-region="">

                                        <div id="536fc88b-ad74-4ed6-af84-90d41cdf1b4f"
                                            className="containerv2 containerv2--w-full containerv2--h-left">
                                            <div className="containerv2__regions"  >
                                                <div className="containerv2__region containerv2__region--12" data-region="">

                                                    <div id="a1d157a4-7d65-4780-b048-e61311bb5584"
                                                        className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <h1 className="text-center text-md-left">Our Story Begins With Our Brands and Clients
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="containerv2__region containerv2__region--8" data-region="">

                                        <div id="7330f0ad-1a50-4380-baa5-f1a6ea4823d3" className="text-content text-content--set">
                                            <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                <p>As
                                                        the pioneers of a one-of-a-kind, full-scale cannabis company, we can manufacture
                                                        medical marijuana products of every facet. At Stash House, our primary goal is
                                                        to streamline and simplify every process from beginning to end to supply the
                                                        highest quality medical cannabis products in each of our residential states.
                                                        With our rich history in the arts of logistics and distribution, we aim to
                                                        continue expanding our expertise into the health and wellness business as one of
                                                        the best medical marijuana manufacturers and distributors in the nation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            <hr style={{'opacity':'.3'}}/>
                            <div id="SH35fcf078-aca6-4065-a7b4-ea98f7593153" className="containerv2 containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--4" data-region="">

                                        <div id="085e0746-9967-45ab-ad8d-0ba12750aa78"
                                            className="containerv2 containerv2--w-full containerv2--h-left">
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--12" data-region="">

                                                    <div id="3a11b5ef-ae15-43f3-9df5-57b5d7bfc77e"
                                                        className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <h1>Industry Leader</h1>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="containerv2__region containerv2__region--8" data-region="">

                                        <div id="8bee1793-aa01-4cc8-83ea-cb29ad94d341" className="text-content text-content--set">
                                            <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                <p>As
                                                        the leading medical marijuana manufacturing and distributing company in the
                                                        nation, Stash House strives to build relationships with local growers and
                                                        processors to provide the highest quality products. Stash House also aims to
                                                        simplify the distribution of medical marijuana while bringing your customers the
                                                        best goods, services, and educational support. By pioneering our one-of-a-kind
                                                        facility, we’re able to produce not only flower products (infused blunts,
                                                        pre-rolls, etc.) and edibles, but also transdermal patches, vapes, and
                                                        concentrates in-house.<br/><br/></p>
                                                <p>The
                                                        state-of-the-art design of our platform allows your dispensary to easily
                                                        navigate a wide selection of brand-name products and benefit from frequent
                                                        deliveries and the most consistent routes in the state. By partnering with us,
                                                        you will have exclusive access to our portfolio, including national brands such
                                                        as Cheeba Chews, Green Hornet, Dabba, 1906 Rove, Crumble, Kiva, Fireball, Dixie,
                                                        Mary’s Wana, Packwoods, Saints, and many more</p>
                                                <p>We are excited to partner with you!</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            
                            </div>
                        </div>

                    </div>
                
                </div>
               
                <div id="SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5" className="containerv2">
                    <div className="containerv2__regions" data-container-regions="">
                        <div className="containerv2__region containerv2__region--12" data-region="">
                            
                            <div id="SH8c1aaa00-9388-4f3b-9cb5-561cbc74c6e1"
                                className="containerv2 containerv2--w-full containerv2--h-left containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">

                                       
                                        <div id="97fb7ea2-6248-44cc-b7f6-f9db68d8fff9"
                                            className="containerv2 containerv2--w-full containerv2--gutter">
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--4" data-region="">

                                                    <div id="29539728-1cf4-49a8-ad7e-2bc34602e55e"
                                                        className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                        <p>GMP &amp; COMPLIANCE</p>
                                                            <h2>Manufacturing</h2>
                                                        </div>
                                                    </div>
                                                    <div id="e8931695-a2c7-457d-9a44-dff9c26bff1f" className="video video--load-in-view ">
                                                    <IFrameVideo h1Tag="MANUFACTURING" pTag="Keeping things in motion" iFrameSrc="https://player.vimeo.com/video/742801964?background=1?rel=0&autoplay=1&muted=1&loop=1"/>
                                                    </div>
                                                </div>
                                                <div className="containerv2__region containerv2__region--8" data-region="">

                                                    <div id="6014beed-b02d-49f7-86cd-9ccb1c99d149"
                                                        className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>The manufacturing journey begins by taking the necessary steps to build
                                                                relationships with local cannabis growers and processors to source only
                                                                the best raw materials. For optimal results, our compliance team double
                                                                tests both raw and finished goods to ensure the safety and quality of
                                                                each finished product. Operating within GMP guidelines, our facility
                                                                offers compartmentalized manufacturing floors, including allergen rooms,
                                                                to produce consistent and high-quality medical-grade products.</p>
                                                            <p><br /></p>
                                                            <ul>
                                                                <li>Building Relationships with Locals</li>
                                                                <li>Testing and Compliance</li>
                                                                <li>GMP (Good Manufacturing Practices)</li>
                                                                <li>Compartmentalize Manufacturing Floor</li>
                                                                <li>Allergen Rooms</li>
                                                                <li>Highest Quality Medical Grade Products</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                           
                                        </div>
                                    </div>

                                </div>
                            
                            </div>
                            <div id="SH71746aa8-2ef9-4fab-b5cf-0ab5dff1380b" className="containerv2 containerv2--w-full containerv2--h-left containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">

                                    
                                        <div id="9aa978f7-735f-4853-96b6-6e81c5717d39" className="containerv2 containerv2--w-full containerv2--gutter">
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--4" data-region="">

                                                    <div id="3deeb7ea-7e7d-42d2-a15a-f4a474bbf1f8" className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>EDUCATION AND SUPPORT</p>
                                                            <h2>Captive<br/>Sales Team</h2>
                                                        </div>
                                                    </div>
                                                    <div id="31e26821-d618-4492-988a-fd1da2b490b5" className="image">

                                                        <img className="image__img" alt="Sales Team" loading="lazy"
                                                            src={img1}
                                                            />
                                                    </div>
                                                </div>
                                                <div className="containerv2__region containerv2__region--8" data-region="">

                                                    <div id="d8a8254f-e7e4-4cc1-89ea-32ffa4fba86d" className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>The state-of-the-art order management software was designed with you in mind. As soon
                                                                as you input your business information, you can connect with your sales rep and
                                                                place your first order. With flexible, frequent delivery dates and no transportation
                                                                fees, you can rest assured your orders will arrive on your time. For compliance
                                                                needs, access product labs and testing documentation online 24/7.</p>
                                                            <ul>
                                                                <li>Ample Reps Per Territory</li>
                                                                <li>Rep Education and Materials</li>
                                                                <li>Marketing Assets and Displays etc.</li>
                                                                <li>Weekly Promo Email and Texts from Stash House</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            <div id="SH71746aa8-2ef9-4fab-b5cf-0ab5dff13876" className="containerv2 containerv2--w-full containerv2--h-left containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">

                                    
                                        <div id="9aa978f7-735f-4853-96b6-6e81c5717d39" className="containerv2 containerv2--w-full containerv2--gutter">
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--4" data-region="">

                                                    <div id="3deeb7ea-7e7d-42d2-a15a-f4a474bbf1f8" className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>STATE OF THE ART CAPTIVE<br/>ONLINE ORDERING PLATFORM</p>
                                                            <h2>Technology<br/>Suite</h2>
                                                        </div>
                                                    </div>
                                                    <div id="31e26821-d618-4492-988a-fd1da2b490b5" className="image">

                                                        <img className="image__img" alt="Sales Team" loading="lazy"
                                                            src={img2}/>
                                                    </div>
                                                </div>
                                                <div className="containerv2__region containerv2__region--8" data-region="">

                                                    <div id="d8a8254f-e7e4-4cc1-89ea-32ffa4fba86d" className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>The state-of-the-art order management software was designed with you in mind. As soon as you input your business information, you can connect with your sales rep and place your first order. With flexible, frequent delivery dates and no transportation fees, you can rest assured your orders will arrive on your time. For compliance needs, access product labs and testing documentation online 24/7.</p>
                                                            <ul><li>Easy Onboarding</li><li>Immediate Access to Sales Rep</li><li>Flexible and frequent delivery dates</li><li>Access to testing and labs</li></ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            <div id="SHe7141856-8ba3-4c23-a512-c6c0d0d1e84f" className="containerv2 containerv2--w-full containerv2--gutter" >
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--6" data-region="">

                                        <div id="2c1b7942-9a70-4cfc-a97c-116798995f9f" className="text-content text-content--set">
                                            <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                <h2 className="text-center">Dispensary View</h2>
                                            </div>
                                        </div>
                                        <div id="4f7ce40a-ad2a-4c15-84a0-e541bc734367"
                                            className="containerv2 containerv2--view-container containerv2--w-full">
                                            <div className="containerv2__regions" data-container-regions="" >
                                                <div className="containerv2__region containerv2__region--12" data-region="">
                                                <div className="slide-container">
                                                    <Slide ref={dispensaryGallery} {...properties}>
                                                        {dispensarySlideImages.map((slideImage, index)=> (
                                                            <div key={index}>
                                                                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                                                    
                                                                </div>
                                                            </div>
                                                        ))} 
                                                    </Slide>
                                                    <div className="stashhouse-slideshow__navigation">
                                                        <button className="sxx-slideshow__navigation-prev"  onClick={()=> g1back()}>
                                                            <RiArrowLeftSLine className="gallery-prev"/>
                                                        </button>
                                                        <button className="sxx-slideshow__navigation-next"  onClick={()=> g1next()}>
                                                            <RiArrowRightSLine className="gallery-next"/>
                                                        </button>
                                                    </div>
                                                </div>

                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="containerv2__region containerv2__region--6" data-region="">

                                        <div id="2c1b7942-9a70-4cfc-a97c-116798995f9f" className="text-content text-content--set">
                                            <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                <h2  className="text-center">Brand View</h2>
                                            </div>
                                        </div>
                                        <div id="4f7ce40a-ad2a-4c15-84a0-e541bc734367"
                                            className="containerv2 containerv2--view-container containerv2--w-full">
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--12" data-region="">
                                                <div className="slide-container">
                                                    <Slide ref={brandsGallery} {...properties}>
                                                        {brandsSlideImages.map((slideImage, index)=> (
                                                            <div key={index}>
                                                                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                                                    
                                                                </div>
                                                            </div>
                                                        ))} 
                                                    </Slide>
                                                    <div className="stashhouse-slideshow__navigation">
                                                        <button className="sxx-slideshow__navigation-prev"  onClick={()=> g2back()}>
                                                            <RiArrowLeftSLine className="gallery-prev"/>
                                                        </button>
                                                        <button className="sxx-slideshow__navigation-next"  onClick={()=> g2next()}>
                                                            <RiArrowRightSLine className="gallery-next"/>
                                                        </button>
                                                    </div>
                                                </div>

                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div id="SH71746aa8-2ef9-4fab-b5cf-0ab5dff13879" className="containerv2 containerv2--w-full containerv2--h-left containerv2--gutter">
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">

                                    
                                        <div id="9aa978f7-735f-4853-96b6-6e81c5717d39" className="containerv2 containerv2--w-full containerv2--gutter">
                                            <div className="containerv2__regions" data-container-regions="">
                                                <div className="containerv2__region containerv2__region--4" data-region="">

                                                    <div id="3deeb7ea-7e7d-42d2-a15a-f4a474bbf1f8" className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>KEEP YOU CONNECTED</p>
                                                            <h2>Distribution<br/>and Logistics</h2>
                                                        </div>
                                                    </div>
                                                    <div id="31e26821-d618-4492-988a-fd1da2b490b5" className="image">

                                                        <IFrameVideo h1Tag="MANUFACTURING" pTag="Keeping things in motion" iFrameSrc="https://player.vimeo.com/video/751716985?h=63fe4205c7&amp;autoplay=1&amp;muted=1&amp;loop=1"/>
                                                    </div>
                                                </div>
                                                <div className="containerv2__region containerv2__region--8" data-region="">

                                                    <div id="d8a8254f-e7e4-4cc1-89ea-32ffa4fba86d" className="text-content text-content--set">
                                                        <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                            <p>With our ease of ordering platform and frequent delivery options, simply choose the products your patients love and select the most convenient delivery option for you and your business with no transportation fees.</p>
                                                            <ul><li>Easy Onboarding</li><li>Immediate Access to Sales Rep</li><li>Flexible and frequent delivery dates</li><li>Access to testing and labs</li></ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <FloatingContact />
            </div>
            <Footer/>
        </>
   )
}