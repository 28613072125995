
import './style.css';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import FloatingContact from '../../layout/FloatingContact';


import Hero from './Hero';
import ListGrid from './ListGrid';
import Map from './Map';
import NationalPartners from './NationalPartners';
import CallToAction from './CallToAction';

export function HomePage() {
  return (
    <>
      <Header/>
      
      <div className="App">
        <Hero/>
        <ListGrid />
        <Map />
        <NationalPartners />
        <CallToAction/>
        <FloatingContact/>
      </div>
      
      <Footer/>
    </>
  );
}
