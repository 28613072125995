import './style.css';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

export function SiteMapPage() {
    
    return (
        <>
            <Header/>
            
            <div className="App">
                <div id="SH240a2413-d9e4-4ea4-b814-592a0c47aa6d" className="text-content">
                    <div className="text-content__content" data-wysiwyg>
                        <h1 className="text-center" >Site Map</h1>
                        <div className="sitemap__container">
                            <div className="sitemap__list">
                                    <div className="sitemap__item">
                                        <a href="/" className="sitemap__link">Homepage</a>
                                    </div>
                                    
                                    <div className="sitemap__item">
                                        <a href="/about/" className="sitemap__link" >About Us</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/missouri/" className="sitemap__link" >Missouri</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/oklahoma/" className="sitemap__link" >Oklahoma</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/register/" className="sitemap__link" >Register</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/contact-us/" className="sitemap__link" >Contact Us</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/new-mexico/" className="sitemap__link" >New Mexico</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/distribution/" className="sitemap__link" >Distribution</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/manufacturing/" className="sitemap__link" >Manufacturing</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/privacy-policy/" className="sitemap__link" >Privacy Policy</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/technology-suite/" className="sitemap__link" >Technology Suite</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/technology-suite-brands/" className="sitemap__link" >Technology Suite - Brands</a>
                                    </div>
                                    <div className="sitemap__item">
                                        <a href="/technology-suite-dispensary/" className="sitemap__link" >Technology Suite - Dispensary</a>
                                    </div><br/><br/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <Footer/>
        </>
    );
}
