import './style.css';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import FloatingContact from '../../layout/FloatingContact';
import Gallery from './Gallery'
import BrandTiles from '../../components/BrandTiles';

export function MissouriPage() {
    return (
      <>
        <Header/>
      
        <div className="App">
            <div id="SH9aa49ea8-fc20-4cb1-b951-6c39cf07b46a" className="containerv2 text--full-span containerv2--h-middle">
                <div className="containerv2__regions" data-container-regions>
                    <div className="containerv2__region containerv2__region--12" data-region>

                        <div id="34e4db4f-4756-4b5c-bcb2-d33dff9860f7" className="text-content">
                            <div className="text-content__content" data-wysiwyg>
                                <h1>Our Missouri Brands</h1>
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
  
            <BrandTiles companyState="MO"/>
            <FloatingContact />
        </div>
      
        <Footer/>
      </>
    );
  }
  