
import '../../../assets/css/listgrid.css';
import './style.css';
import bagIcon from '../../../assets/img/shopping-bag.svg';
import boxOpenIcon from '../../../assets/img/box-open.svg';
import fireIcon from '../../../assets/img/fire.svg';
import pdfIcon from '../../../assets/img/file-pdf.svg';

const ListGrid = () => {
    return (
      <>
        <div id="ba9fda79-7591-49aa-b0ad-2545f645244e" className="containerv2" style={{padding: '50px 0'}}>
            <div className="containerv2__regions" data-container-regions style={{'maxWidth': '1320px'}}>
                <div className="containerv2__region containerv2__region--12" data-region>
                <div id="3bde1f73-2638-4fc2-af34-e4e14b0b5353" className="text-content">
                    <div className="text-content__content" data-wysiwyg>
                            <h1>Proprietary &amp; Intuitive Dispensary eCommerce Platform</h1>
                        </div>
                    </div>

                    <div id="SH44336132-5cd1-43ab-8946-a8f4c449438b"
                        className="listgrid shouse-homelist listgrid--icon-list listgrid--align-left listgrid--justify listgrid--justify-even"
                        data-carousel-items="1">
                        <ul className="listgrid__list">
                            <li className="listgrid__item">
                                <div className="listgrid__header">
                                    <div className="listgrid__icon text-center">
                                        <img src={bagIcon} className="icon" alt="cog icon"/>
                                    </div>
                                  
                                </div>
                                <div className="text-content__content" data-wysiwyg>
                                    <h5 className="text-center">700+ Products</h5>
                                </div>
                            </li>
                            <li className="listgrid__item">
                                <div className="listgrid__header">
                                    <div className="listgrid__icon text-center">
                                        <img src={boxOpenIcon} className="icon" alt="cog icon"/>
                                    </div>
                                  
                                </div>
                                <div className="text-content__content" data-wysiwyg>
                                    <h5 className="text-center">New Product<br />Releases</h5>
                                </div>
                            </li>
                            <li className="listgrid__item">
                                <div className="listgrid__header">
                                    <div className="listgrid__icon text-center">
                                        <img src={fireIcon} className="icon" alt="cog icon"/>
                                    </div>
                                  
                                </div>
                                <div className="text-content__content" data-wysiwyg>
                                    <h5 className="text-center">Hot Deals</h5>
                                </div>
                            </li>
                            <li className="listgrid__item">
                                <div className="listgrid__header">
                                    <div className="listgrid__icon text-center">
                                        <img src={pdfIcon} className="icon" alt="cog icon"/>
                                    </div>
                                  
                                </div>
                                <div className="text-content__content" data-wysiwyg>
                                    <h5 className="text-center">Digital Library For All COA's Order History</h5>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>  
            </div>
        </div>
      </>
    )
}
 
export default ListGrid
