import { RiFacebookFill, RiTwitterFill, RiLinkedinFill, RiInstagramFill } from 'react-icons/ri';


import logo from '../../assets/img/a5607a2a-0ee1-4f1e-8315-ca59b40c5dcb.png';
import './style.css';
import { Link } from 'react-router-dom';

import React from 'react';

const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <footer id="ff69cf68-3f16-429b-8bf1-93579791911d" className="containerv2 containerv2--gutter c-bg c-bg--dark">
            <div className="containerv2__regions" data-container-regions>
                <div className="containerv2__region containerv2__region--12" data-region>

                    <div id="d6e5fc42-0b55-49bc-ae8d-fe046b43e7d3" className="containerv2 containerv2--footertop containerv2--w-full containerv2--inl containerv2--inl-auto containerv2--middle containerv2--h-left">
                        <div className="containerv2__regions" data-container-regions>
                            <div className="containerv2__region containerv2__region--3" data-region>
                                <div id="24d97b88-5f9d-4652-9b6c-0491c97e7282" className="link-region">
                                    <Link to="/" className="link-region__link">

                                        <div id="0e3f8683-26ac-421e-a3e7-b0a2f8e1e10e" className="logo">
                                            <img src={logo}
                                                className="logo__img" alt="Stash House Corporate" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="containerv2__region containerv2__region--4" data-region>
                            </div>
                            <div className="containerv2__region containerv2__region--5" data-region>
                                <div id="cb8a3913-a987-44b1-b214-63cc621bacfa">
                                    <Link to="/" className="button button--align-left c-bg c-bg--dark">Home</Link>
                                </div>
                                <div id="b614b438-d902-4d5d-bc0f-260619b8741e">
                                    <Link to="/about" target="" className="button button--align-left c-bg c-bg--dark">About Us</Link>
                                </div>
                                
                            </div>

                        </div>
                        <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
                        </div>
                    </div>
                    <div id="b8f1342c-7e31-4de2-b498-bc0b98758486" className="containerv2 containerv2--footerline containerv2--w-full">
                        <div className="containerv2__regions" data-container-regions>
                            <div className="containerv2__region containerv2__region--7" data-region>

                                <div id="c2c79705-a03e-4dac-95d8-81b7b4f655ac" className="containerv2 containerv2--w-full">
                                    <div className="containerv2__regions" data-container-regions>
                                        <div className="containerv2__region containerv2__region--12" data-region>

                                            <div id="c6d16c29-70e9-442a-b945-043e16dbf355" className="text-content">
                                                <div className="text-content__content" data-wysiwyg>
                                                    <h5><a href="tel:866-200-3155" data-mce-href="tel:866-200-3155"
                                                            data-mce-selected="inline-boundary">866-200-3155 </a><br data-mce-bogus="1"/></h5>
                                                </div>
                                            </div>


                                            <div id="438e8ce9-49ad-4758-8e89-dc8b2ed629ec">
                                                <a href="/contact-us/" target=""
                                                    className="button button--align-left c-bg c-bg--primary c-tx c-tx--white">Get
                                                    in Touch</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="containerv2__background" aria-hidden="true" data-container-background
                                        data-region>
                                    </div>
                                </div>
                            </div>
                            <div className="containerv2__region containerv2__region--5" data-region>

                                <div id="0c17480d-1995-4bd8-b067-d66afacc0242" className="containerv2 containerv2--login-button-bar containerv2--w-full containerv2--inl containerv2--inl-auto containerv2--h-right">
                                    <div className="containerv2__regions" data-container-regions>
                                        <div className="containerv2__region containerv2__region--12 " data-region>
                                            <div id="a17a849d-9423-427d-b9a1-d82eea406594">
                                                <a href="https://dashboards.stashhousedistro.com" target=""
                                                    className="button button--align-left button--login-link c-bg c-bg--dark">Brand
                                                    Login</a>
                                            </div>
                                            <div id="f7eed67a-bd0d-4b9f-b6c1-178df4fc3958">
                                                <a href="/register/" target=""
                                                    className="button button--align-left button--login-link c-bg c-bg--dark">Dispensary
                                                    Login</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="containerv2__background" aria-hidden="true" data-container-background
                                        data-region>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
                        </div>
                    </div>
                    <div id="066d771d-305d-487b-9901-efac963d717f" className="containerv2 footer__bottom-links-bar containerv2--w-full containerv2--h-left" >
                        <div className="containerv2__regions" data-container-regions>
                            <div className="containerv2__region containerv2__region--9" data-region>

                                <div id="16acd080-de54-4588-92ec-30d73f11dd43"
                                    className="text-content footer__bottom-links-container">
                                    <div className="text-content__content" data-wysiwyg>
                                        <p className="footer__bottom-links-copyright mr-sm-0 mb-sm-1">Stash House Distribution © {year}.<br/>All
                                                Rights Reserved.</p>
                                        <ul className="footer__bottom-links-list mr-sm-0">
                                            <li className="footer__bottom-links-item"><a href="/privacy-policy/" rel="noopener"
                                                    data-mce-href="/privacy-policy/">Privacy Policy</a><br
                                                    data-mce-bogus="1" /></li>
                                            <li className="footer__bottom-links-item"><a href="/disclaimer/" rel="noopener"
                                                    data-mce-href="/disclaimer/">Disclaimer</a><br data-mce-bogus="1" /></li>
                                            <li className="footer__bottom-links-item"><a href="/do-not-sell-my-info/"
                                                    rel="noopener" data-mce-href="/do-not-sell-my-info/">Do Not Sell My
                                                    Personal Information</a><br data-mce-bogus="1" /></li>
                                            <li className="footer__bottom-links-item"><a href="/site-map/" rel="noopener"
                                                    data-mce-href="/site-map/">Site Map</a><br data-mce-bogus="1" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="containerv2__region containerv2__region--3" data-region>

                                <div className="socialmedia" id="5c3f5beb-cba8-4835-a641-343f956cb92d">
                                    <div className="footer--sub-col-social">

                                        <ul className="social-media no-print">
                                            <li>
                                                <a href="https://www.facebook.com/Stash-House-Distro-102835894991849/"
                                                    target="_blank" rel="noreferrer">
                                                    <RiFacebookFill className="App-social"/>

                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/stashhousedist" target="_blank" rel="noreferrer">
                                                    <RiTwitterFill className="App-social"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/stash-house-distribution/"
                                                    target="_blank" rel="noreferrer">
                                                    <RiLinkedinFill className="App-social"/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/stashhouseok/" target="_blank" rel="noreferrer">
                                                    <RiInstagramFill className="App-social"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
                        </div>
                    </div>
                
                </div>

            </div>
            <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
            </div>
        </footer>
    )
  }
  
export default Footer
