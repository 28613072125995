import { useEffect} from 'react';
import { Link } from 'react-router-dom';
import diceIcon from '../../../assets/img/dice-d10.svg';
import atomIcon from '../../../assets/img/atom.svg';


import './style.css';

const CallToAction = () => {
        useEffect(() => {
           
        return () => {
           
        }
    }, []);
    return (
        <>
            <div id="SH2f7f257a-a35e-4d6c-83dd-d1e845e72c51" className="containerv2 containerv2--no-text-margin containerv2--inl containerv2--inl-auto containerv2--gutter c-bg c-bg--dark">
                <div className="containerv2__regions" data-container-regions style={{maxWidth: '1386px'}}>
                    <div className="containerv2__region containerv2__region--5" data-region>

                        <div id="b96a0f1d-2bbc-4c84-a157-7dbf408d506e" className="text-content">
                            <div className="text-content__content" data-wysiwyg>
                                <h6><span className="font-fam--primary">BEVERYTHING UNDER ONE ROOF</span></h6>
                                <h1>The Nation's Leading Cannabis Manufacturer &amp; Distributor</h1>
                            </div>
                        </div>
                        
                    </div>
                    <div className="containerv2__region containerv2__region--7" data-region>

                        <div id="SH0271ba29-432d-40b9-ba38-48d9c574c6df"
                            className="containerv2 containerv2--gutter-l containerv2--w-full">
                            <div className="containerv2__regions" data-container-regions>
                                <div className="containerv2__region containerv2__region--6 mb-sm-1" data-region>

                                    <div id="4bdd9d22-1951-4afb-a73d-56828222b252" className="text-content">
                                        <div className="text-content__content" data-wysiwyg>
                                            <div className="text--icon-title mb-sm-1">
                                                <p>
                                                    <img src={diceIcon} alt="dice icon" width="50" height="50" className="icon"/>
                                                </p>
                                                <h5><span className="font-fam--primary">BRANDS</span></h5>
                                            </div>
                                            <p className="mb-1">Motivated by integrity, manufacturing the highest quality products, and only
                                                partnering with the best who deliver the best.</p>
                                            <a href="https://dashboards.stashhousedistro.com" rel="noreferrer" target="_blank" className="button button--align-left c-bg c-bg--primary c-tx c-tx--white">
                                               Brand Login
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="containerv2__region containerv2__region--6" data-region>

                                    <div id="SH3c130862-af9f-40fd-8397-c177aff27e6a" className="text-content">
                                        <div className="text-content__content" data-wysiwyg>
                                            <div className="text--icon-title mb-sm-1">
                                                <p>
                                                    <img src={atomIcon} alt="atom icon" width="50" height="50" className="icon" />
                                                </p>
                                                <h5><span className="font-fam--primary">DISPENSARIES</span></h5>
                                            </div>
                                            <p className="mb-1">Stash House's proprietary and intuitive dispensary eCommerce platform makes it
                                                easy for our customers.</p>
                                            <Link to="/register"  className="button button--align-left c-bg c-bg--primary c-tx c-tx--white">
                                               Dispensary Login
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}
 
export default CallToAction
