import './style.css';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

export function PrivacyPage() {
    
    return (
        <>
            <Header/>
            
            <div className="App">
                <div id="SH240a2413-d9e4-4ea4-b814-592a0c47aa6d" className="text-content">
                    <div className="text-content__content" data-wysiwyg>
                        <h1 className="text-center;" >Privacy
                                Policy</h1>
                        <p>© 2019 Stash House Distribution. No portion of this website — inclusive of, but not limited
                            to, photographs, illustrations, copy and look &amp; feel — may be used or reproduced by any
                            means without the express written consent of Stash House Distribution. All trademarks herein
                            are the property of their respective owners who have all the rights therein. No trademarks
                            or service marks may be used or reproduced without the express written consent of their
                            respective owners.</p>
                        <p align="center"><strong>Privacy Statement</strong></p>
                        <p>We respect your privacy. The information you provide us is used to stay in communication with
                            you via email, phone, and USPS mail. We may provide you with special offers, announcements
                            and invitations when you provide us with your information. Your information will not be sold
                            or shared.</p>
                        <p align="center"><strong>What data we collect</strong></p>
                        <p>Stash House may collect the following information about you:</p>
                        <ul>
                            <li>Your name</li>
                            <li>Your contact details (email address, telephone numbers).</li>
                            <li>Your correspondence with Stash House.</li>
                            <li>Your communication and marketing preferences.</li>
                            <li>Your interests, preferences, feedback, and survey responses.</li>
                            <li>Your online browsing activities on mmjdist.azurewebsites.net.</li>
                            <li>Your location.</li>
                            <li>Other publicly available information you have shared via a public platform.</li>
                        </ul>
                        <p>In certain instances, such as applications for residency, we may need to collect more
                            detailed personal information. Some of your personal data is collected in a direct manner;
                            for example, when you provide your name and contact details to submit an inquiry with Stash
                            House Distribution. Other personal data may be collected indirectly; for example, your
                            browsing history as you interact with our website. We may also receive personal data from
                            trusted third parties who provide services to Stash House.</p>
                        <p align="center"><strong>Use of Cookies</strong></p>
                        <p>A cookie is a small text file containing information that a web site transfers to your
                            computing device for record-keeping purposes. A cookie cannot give us access to any
                            information beyond what you provide us. Cookies store information about browsing behavior
                            and consumer preferences and can be used to analyze the user experience and personal journey
                            around our website. Most web browsers automatically accept and save cookies; consult your
                            browser's help documentation if you want information about altering your browser's settings
                            regarding cookie processing. If you disable cookies you may still view the publicly
                            available information on our website.</p>
                        <p align="center"><strong>Google Analytics</strong></p>
                        <p>Stash House Distribution uses a third-party service, Google Analytics, to collect standard
                            internet log information and details of behavior patterns for visitors of our website. Stash
                            House Distribution does not make any attempt to correlate that usage data with a personal
                            identity. We use this service to find out how our visitors are interacting with our website,
                            what changes we can make to improve the browsing experience, and to evaluate the results of
                            our marketing efforts.</p>
                        <p align="center"><strong>Our partners</strong></p>
                        <p>In the exercise of providing certain services to you, we may have a need to share some of
                            your personal data with one of our trusted partners.</p>
                        <p>Stash House Distribution will only transmit your personal data to partners who share our
                            commitment to data protection and privacy.</p>
                        <p>Aside from our trusted service providers, Stash House Distribution will not disclose or sell
                            your personal data to any third party, unless required by law.</p>
                        <p align="center"><strong>Legal basis for processing data</strong></p>
                        <p>Stash House Distribution collects and processes customers' personal data because it is
                            required for the pursuit of legitimate business interests:</p>
                        <ul>
                            <li>Selling and supplying products and services to our customers.</li>
                            <li>Fulfilling duties to our customers, employees, shareholders and other stakeholders.</li>
                            <li>Promoting, marketing, and advertising our products and services.</li>
                            <li>Sending promotional communications relevant to specific customers.</li>
                            <li>Understanding our customer's needs, activities, preferences, and behavior.</li>
                            <li>Offering improvements to our existing products and services and developing new products
                                and services.</li>
                            <li>Complying with legal and regulatory obligations.</li>
                            <li>Being effective in defense of legal claims or regulatory enforcement actions.</li>
                            <li>Preventing, detecting, and investigating crime or fraud, including working with law
                                enforcement agencies.</li>
                            <li>Protecting Stash House, its customers and employees, by taking legal action against
                                criminal acts of third parties.</li>
                        </ul>
                        <p align="center"><strong>Your rights</strong></p>
                        <p>You have the following rights:</p>
                        <ul>
                            <li>The right of access. The right to ask for a copy of your personal data that we retain.
                            </li>
                            <li>The right to erasure. The right, in certain circumstances, to ask us to delete your
                                personal data, should we no longer have a legal reason to hold it.</li>
                            <li>The right of rectification. The right to ask for your personal data be updated or
                                corrected.</li>
                            <li>The right to object. The right to opt out of marketing communication or object to the
                                use of your data should we not have a legitimate reason to do so.</li>
                            <li>The right to restrict processing. The right, in certain circumstances, to ask us to
                                retain and secure your personal data but not otherwise use it.</li>
                            <li>The right of data portability. The right, in certain circumstances, to ask us to prepare
                                and transmit some of your personal data to another organization in a structured format.
                            </li>
                        </ul>
                        <p>If you wish to exercise any of the rights listed above, please email&nbsp;<a
                                href="mailto:datarights@stashhousedistro.com">datarights@stashhousedistro.com</a>&nbsp;with the
                            details of your request.</p>
                        <p align="center"><strong>Updates</strong></p>
                        <p>This policy was last updated in April 18th, 2023. We reserve the right to change our
                            privacy policy at any time. The latest version of our policy can always be found on this web
                            page.</p>
                       
                        <p align="center"><strong>Questions and feedback</strong></p>
                        <p>We welcome your questions, comments, and concerns about privacy. Please send us any and all
                            feedback pertaining to privacy, or any other issue. If you want to send feedback to us about
                            a privacy issue or anything else,&nbsp;<a href="mailto:datarights@stashhousedistro.com"
                                >CLICK HERE.</a></p>
                        <p align="center"><strong>Online Policy</strong></p>
                        <p>This online privacy policy applies only to information collected through our website and not
                            to information collected offline.</p>
                        <p align="center"><strong>By using our site, you consent to our privacy policy.</strong></p>
                    </div>
                </div>
            </div>
            
            <Footer/>
        </>
    );
}
