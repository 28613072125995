
import { useForm, useFormState } from "react-hook-form";
import axios from "axios";
import React, { useState } from "react";
//import reCAPTCHA from "react-google-recaptcha"

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';


import userIcon from '../../assets/img/user.svg';
import envelopeIcon from '../../assets/img/envelope.svg';
import phoneIcon from '../../assets/img/phone.svg';
import infoCircleIcon from '../../assets/img/info-circle.svg';
import checkboxCheckedIcon from '../../assets/img/checkbox-checked.svg';
import checkboxUncheckedIcon from '../../assets/img/checkbox-unchecked.svg';
import { RiCloseCircleLine } from 'react-icons/ri';

import './style.css';

const baseURL = "https://ok.stashhousedistro.com";

export function ContactUsPage() {
    const [buttonText, setButtonText] = useState("Send Message");
    const [sending, setSending] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
   
    const {
        register,
        handleSubmit,
        resetField,
        control,
        formState: { errors },
      } = useForm()
    const { isSubmitting, isValidating } = useFormState({ control })

    React.useEffect(() => {
    
    }, []);

    const onSubmit = (data) => {
        setSending(true);
        setButtonText("Sending...");
       
        console.log(JSON.stringify(data));
        console.log()
        sendEmail(data)

    };

    function closeMessage() {
        setSending(false);
        setButtonText("Send Message");
        resetForm();
    }
    const Switch = props => {
        const { test, children } = props
        // filter out only children with a matching prop
        return children.find(child => {
          return child.props.value === test
        })      
      }

    const resetForm = () => {
        resetField("firstName");
        resetField("lastName");
        resetField("email");
        resetField("phone");
        resetField("companyState");
        resetField("business");
        resetField("subject");
        resetField("message");
    }
   

   

    async function sendEmail(data) {
        
        console.log(data.firstName);
        

        try {
            var params = new URLSearchParams();
            params.append('firstName', data.firstName);
            params.append('lastName', data.lastName);
            params.append('phone', data.phone);
            params.append('email', data.email);
            params.append('state', data.companyState);
            params.append('business', data.business);
            params.append('subject', data.subject);
            params.append('message', data.message);
            const response = await axios.post(`${baseURL}/ajax/shd_website_contact_form_2023.asp`, params,)
            setResponseStatus(response.status);
            
        } catch (error) {
            setResponseStatus(500);
        }

        
        
    
    }

    return (
        <>
            <Header/>
            
            <div className="App">
                <div id="SH0d51fab7-333d-477b-b392-43cfd40ca67b" className="containerv2">
                    <div className="containerv2__regions" data-container-regions>
                        <div className="containerv2__region containerv2__region--12" data-region>

                            <div id="6bd72036-f103-4081-9630-8f6f19b58e84" className="text-content">
                                <div className="text-content__content" data-wysiwyg>
                                    <h1>Contact Us</h1>
                                </div>
                            </div>
                            <div id="6992b8f9-0531-48cb-80be-450eaf258a3e" className="text-content">
                                <div className="text-content__content" data-wysiwyg>
                                    <h1>Interested in Stash House?</h1>
                                </div>
                            </div>
                            <div id="27bf192a-23f4-4f94-9ed5-23b808634145" className="form ">
                                <form onSubmit={handleSubmit(onSubmit)} title="Contact" id="contact-form">
                                
                                    <div className="form-overlay">
                                        
                                    </div>
                                    
                                    <input type="hidden" name="ReferredBy" value="" />
                                    <div data-region>
                                        
                                        
                                        <div id="SH364b7448-32b9-4f9a-821b-4ca7544a5a21" className="containerv2 containerv2--w-full containerv2--gutter">
                                            <div className="containerv2__regions mb-1" data-container-regions>
                                                <div className="containerv2__region containerv2__region--6" data-region>
                                                    <div className={errors.firstName ? "input-error" : ""}>
                                                        <div id="48ebadb9-24a3-4f11-b5ec-3aaf542652dd" className="form form--no-label form--added-icon form--row-1_2">
                                                            <label>
                                                                <span className="form__label form__required">Unlabeled text input</span>
                                                                <input name="firstName" type="text"  placeholder="First name" {...register("firstName", { required: true })} aria-invalid={errors.firstName ? "true" : "false"} 
                                                                    title="" autoComplete="on" />
                                                                {errors.firstName?.type === 'required' && <p className="message" role="alert">First name is required</p>}
                                                            </label>

                                                        </div>
                                                        <div id="2abe6d11-5cb8-4bd4-af05-085a6dbe754e" className="icon">
                                                            <img src={userIcon} width="24px" height="24px" alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <div id="04fce2a7-101c-459a-84db-f8f471b761d5"
                                                        className="form form--no-label form--added-icon form--row-2_2">
                                                        <label>
                                                            <span className="form__label">Unlabeled text input</span>
                                                            <input name="lastName" type="text"  placeholder="Last name" title="" {...register("lastName")} 
                                                                autoComplete="on" />
                                                        </label>

                                                    </div>
                                                    <div id="87741b34-50cc-4418-b43d-b13a0f72f8f2" className="icon">
                                                        <img src={userIcon} width="24px" height="24px" alt="icon"/>
                                                    </div>
                                                </div>
                                                <div className="containerv2__region containerv2__region--6" data-region>
                                                    <div className={errors.email ? "input-error" : ""}>
                                                        <div id="ef2a47c8-ac45-4913-83b1-d5a982f500d3"
                                                            className="form form--no-label form--added-icon form--row-1_2">
                                                            <label>
                                                                <span className="form__label form__required">Unlabeled text input</span>
                                                                <input name="email" type="email" placeholder="Email Address"  {...register("email", { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/  })} aria-invalid={errors.email ? "true" : "false"} 
                                                                   />
                                                                {errors.email && <p className="message" role="alert">Please enter a valid email format</p>}
                                                            </label>

                                                        </div>
                                                        <div id="1321579d-65f9-448d-84a9-d92dbb224a2b" className="icon">
                                                            <img src={envelopeIcon} width="24px" height="24px" alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <div className={errors.phone ? "input-error" : ""}>
                                                        <div id="282e5f74-b29f-4ed1-8d31-6cb9dfa123bc" className="form form--no-label form--added-icon form--row-2_2">
                                                            <label>
                                                                <span className="form__label">Unlabeled text input</span>
                                                                <input name="phone" type="tel" placeholder="Phone" {...register("phone", { pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/ })} aria-invalid={errors.phone ? "true" : "false"}
                                                                    pattern="\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                                    title="Phone number must be 10 digits long." autoComplete="tel" />
                                                                {errors.phone && <p className="message" role="alert">Please enter a valid telephone format</p>}
                                                            </label>
                                                        </div>
                                                        <div id="23b43768-94c0-4c72-9cbc-4643b641bce4" className="icon">
                                                            <img src={phoneIcon} width="24px" height="24px" alt="icon"/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="containerv2__background" aria-hidden="true" data-container-background
                                                data-region>
                                            </div>
                                        </div>
                                        <div id="SH4fc6d71e-50b7-4b4c-a88c-52ce27ab90b8"
                                            className="containerv2 containerv2--w-full containerv2--gutter mb-2">
                                            <div className="containerv2__regions" data-container-regions>
                                                <div className="containerv2__region containerv2__region--6" data-region>

                                                    <div id="46492471-4ec2-4651-b713-45e70bb8ad78" className="form">
                                                        <div className={errors.state ? "input-error" : ""}>
                                                            <label htmlFor="State">
                                                                <span className="form__label form__required font-700">State</span>
                                                                <select id="companyState" name="companyState" 
                                                                    {...register("companyState", { 
                                                                        required: true, validate: (value, formValues) => value !== 'DEFAULT'
                                                                        })
                                                                    }
                                                                >
                                                                    <option value="DEFAULT">Choose One</option>
                                                                    <option value="Oklahoma">Oklahoma</option>
                                                                    <option value="Missouri">Missouri</option>
                                                                    <option value="New Mexico">New Mexico</option>
                                                                    <option value="Mississippi">Mississippi</option>
                                                                    <option value="Montana">Montana</option>
                                                                </select>
                                                                <i className="form__select"></i>
                                                                {errors.state && <p className="message" role="alert">Please select a State</p>}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="containerv2__region containerv2__region--6" data-region>

                                                    <div id="185b224a-c878-44ec-86fc-fdab5e721f76" className="form">
                                                        <div className={errors.business ? "input-error" : ""}>
                                                            <label htmlFor="Business">
                                                                <span className="form__label form__required font-700">Business</span>
                                                                <select id="business" name="business" 
                                                                    {...register("business", { 
                                                                        required: true, validate: (value, formValues) => value !== 'DEFAULT'
                                                                        })
                                                                    }
                                                                >
                                                                    <option value="DEFAULT" disabled>Choose One</option>
                                                                    <option value="Brand">Brand</option>
                                                                    <option value="Dispensary">Dispensary</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                                <i className="form__select"></i>
                                                                {errors.business && <p className="message" role="alert">Please select a Business</p>}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="containerv2__background" aria-hidden="true" data-container-background
                                                data-region>
                                            </div>
                                        </div>
                                        <div id="SHae3deec8-a6fe-4272-8e67-6d79d6965256" className="containerv2 containerv2--w-full containerv2--gutter">
                                            <div className="containerv2__regions mb-1" data-container-regions>
                                                
                                                <div className="containerv2__region containerv2__region--12" data-region>

                                                    <div id="197272bb-5363-4610-acb8-cb8b3c5a3ae4"
                                                        className="form form--no-label form--added-icon form--row-1_1 form--row-last">
                                                        <label>
                                                            <span className="form__label">Unlabeled text input</span>
                                                            <input name="subject" type="text"  placeholder="Type subject" title=""
                                                                autoComplete="on" {...register("subject")} />
                                                        </label>

                                                    </div>
                                                    <div id="46a1e1a8-212f-4acf-b6c1-b0a7cf6c6064" className="icon">
                                                    <img src={infoCircleIcon} width="24px" height="24px" alt="icon"/>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="containerv2__background" aria-hidden="true" data-container-background
                                                data-region>
                                            </div>
                                        </div>
                                        <div id="d5065e4a-e07e-44a9-8579-6568568778a7" className="form form--no-label">
                                            <div className={errors.message ? "input-error" : ""}>
                                                <label>
                                                    <span className="form__label form__required">How can we help you? Feel free to get in
                                                        touch! Type inquiry here.</span>
                                                    <textarea name="message"  {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"} 
                                                        placeholder="How can we help you? Feel free to get in touch! Type inquiry here."
                                                        aria-required="true"  rows="7"></textarea>
                                                    {errors.message && <p className="message" role="alert">This field is required</p>}
                                                </label>
                                            </div>
                                        </div>

                                        <div id="ad7b4458-fa18-4870-9d1f-0d6e33b73a34" className="form text-content">
                                            <div className={errors.privacy ? "input-error" : ""}>
                                                <div className="text-content form__privacy">
                                                    <fieldset aria-describedby="u-FmnBYL">
                                                        <legend className="visuallyhidden">Disclaimer</legend>
                                                        <input type="hidden" name="Privacy_Fail"
                                                            value="In order to comply with rules and regulations protecting your privacy, we are unable to submit your form unless you agree to the terms and usage of your personal data. To agree to these terms, close this message and check yes to allow us to use your information for follow up."/>
                                                        <input type="hidden" name="Privacy_Statement"
                                                            value="By submitting this form, you agree to the usage of your personal data in accordance with our Privacy Policy. You acknowledge that such data will be used for the purpose of fulfilling the request in which the form you are submitting is applicable. You also agree to the acceptance of occasional follow up communication(s) in response to this request, by email, SMS, phone and other electronic means. Message and data rates may apply. Reply STOP to end."/>
                                                        <p id="u-FmnBYL" className="form__disclaimer">By submitting this form, you agree to
                                                            the usage of your personal data in accordance with our <a
                                                                href='/shared/privacy' name='privacyLink' target='_blank'>Privacy
                                                                Policy</a>. You acknowledge that such data will be used for the purpose
                                                            of fulfilling the request in which the form you are submitting is
                                                            applicable. You also agree to the acceptance of occasional follow up
                                                            communication(s) in response to this request, by email, SMS, phone and other
                                                            electronic means. Message and data rates may apply. Reply STOP to end.</p>

                                                        <div className="form__privacy-check">
                                                            <div className="form__privacy-checkboxes">
                                                                <input id="PrHPgSsH" aria-label="I agree" className="visuallyhidden"
                                                                    type="radio" name="Sender_Privacy_Agree" value="Yes" checked
                                                                    {...register("privacy", { 
                                                                        required: true, validate: {
                                                                            validate: (value, formValues) => value === 'Yes'
                                                                        }
                                                                        })
                                                                    }
                                                                />
                                                                <label htmlFor="PrHPgSsH" className="form__checkbox form__checkbox--inline">
                                                                    <i className="form__checkbox-icon">
                                                                        <img src={checkboxUncheckedIcon} width="24px" height="24px" alt="icon"/>
                                                                    </i>
                                                                    <i className="form__checkbox-icon">
                                                                        <img src={checkboxCheckedIcon} width="24px" height="24px" alt="icon"/>
                                                                    </i>
                                                                    <span>I agree</span>
                                                                </label>
                                                            </div>
                                                            <div className="form__privacy-checkboxes">
                                                                <input id="xBNanRvg" aria-label="I do not agree" className="visuallyhidden"
                                                                    type="radio" name="Sender_Privacy_Agree" value="No" 
                                                                    {...register("privacy", { 
                                                                        required: true, 
                                                                        validate: {
                                                                            validate: (value, formValues) => value === 'Yes'
                                                                        }
                                                                        })
                                                                    }
                                                                    />
                                                                <label htmlFor="xBNanRvg" className="form__checkbox form__checkbox--inline">
                                                                    <i className="form__checkbox-icon">
                                                                    <img src={checkboxUncheckedIcon} width="24px" height="24px" alt="icon"/>
                                                                    </i>
                                                                    <i className="form__checkbox-icon">
                                                                    <img src={checkboxCheckedIcon} width="24px" height="24px" alt="icon"/>
                                                                    </i>
                                                                    <span>I do not agree</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        
                                                    </fieldset>
                                                    {errors.privacy && <p className="message" style={{'textAlign': 'center'}} role="alert">Please agree to our privacy policy</p>}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="9a91c384-2974-4394-9c6d-f5dbdaafb153" className="containerv2 containerv2--w-full containerv2--inl containerv2--inl-auto containerv2--middle containerv2--h-middle">
                                            <div className="containerv2__regions" data-container-regions>
                                                <div className="containerv2__region containerv2__region--12" data-region>
                                                    <div id="568acf3f-b275-49ae-b02b-a920b1ec5c70">
                                                        <input type="submit" className="button button--align-left c-bg c-bg--primary c-tx c-tx--white" value={buttonText}/>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="containerv2__background" aria-hidden="true" data-container-background
                                                data-region>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    {/* <reCAPTCHA sitekey={process.env.GOOGLE_RECAPTCH_SITE_KEY}/> */}
                                    <div className="g-recaptcha" data-sitekey="6Lce0NEZAAAAAAcaf6crAS0AvVbYlgExXoBSp2X3"data-size="invisible"></div>
                                    {sending ?(
                                            <div className="sending-overlay">
                                                <div className="sending-overlay-inner">
                                                    
                                                    <Switch test={responseStatus}>
                                                        <div value={200} className="form-response-container">
                                                            <h1>Thank you for contacting Stash House.</h1>
                                                            <p>A Representative will reach out to you as soon as they are able.</p>
                                                            <button className="form-repsonse__close" aria-label="Close main navigation"  onClick={()=> closeMessage()}>
                                                                <RiCloseCircleLine className="App-close"/>
                                                            </button>
                                                        </div>
                                                        <div value={500} className="form-response-container">
                                                            <h1>Oops!  An error has occured</h1>
                                                            <p>We have been notified of this issue and are working on a resolution. </p>
                                                            <p>You can also reach us by email at <a href="mailto:info@stashhousedistro.com">info@stashhousedistro.com</a></p>
                                                            <button className="form-repsonse__close" aria-label="Close main navigation"  onClick={()=> closeMessage()}>
                                                                <RiCloseCircleLine className="App-close"/>
                                                            </button>
                                                        </div>
                                                        <div value={405}></div>
                                                        
                                                    </Switch>
                                                   
                                                       
                                                   
                                                    
                                                </div>
                                            </div>
                                        ) : null}
                                    
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="containerv2__background" aria-hidden="true" data-container-background data-region>
                    </div>
                </div>
            </div>
            
            <Footer/>
        </>
    );
}
