import React, { useState, useEffect } from "react";
import './style.css';
import logo from '../../assets/img/a5607a2a-0ee1-4f1e-8315-ca59b40c5dcb.png';
import background from '../../assets/img/background.jpg';

export function AgeGatePage() {
    const [allowed, setAllowed] = useState(true);

    useEffect(() => {
        if (window.sessionStorage.is21+'' === 'true'){
            window.location.href = "/";
            
        }
     }, []);
    
    function notAllowed() {
        setAllowed(false);
    }

    function is21(){
        window.sessionStorage.is21 = 'true';
        window.location.href = "/";
    }
    return (
        <>
           
            <div className="App">
                <div id="SH4ba8f84b-489c-4ecd-b912-428cb86a867b" className="containerv2 age-gate__text age-gate__container containerv2--middle containerv2--h-middle containerv2--masthead c-bg c-bg--black" >
                    <div className="containerv2__regions" data-container-regions="">
                        <div className="containerv2__region containerv2__region--12" data-region="">

                            <div id="a882d0a0-7764-4fa1-aca2-df9235e0331d" className="logo">
                                <img src={logo} className="logo__img" alt="Stash House Corporate" />
                            </div>
                            <div id="bed54286-298a-4309-8abc-a4a45705f18a" className="text-content age-gate__text text-content--set">
                                <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                    <p><strong><span className="age-gate-disclaimer" >Are you 21 or older?</span></strong></p>
                                </div>
                            </div>
                            {allowed ?(null):
                                (
                                    <>
                                        <div id="0eb1a6e5-16c8-42e5-832b-0ed949292b5b" className="text-content age-gate__text text-content--set">
                                            <div className="text-content__content text-content__content--set" data-wysiwyg="">
                                                <p className="text-center"><span style={{'color': '#d35400'}} ><strong><span className="age-gate-disclaimer">You must be 21 or older to access this site.</span></strong></span></p>
                                            </div>
                                        </div>
                                    </>
                                    )
                            }
                            <div id="6c8da6b5-f9d9-48e7-9d7a-1280126f1b90" className="containerv2 containerv2--inl containerv2--inl-auto containerv2--h-middle" >
                                <div className="containerv2__regions" data-container-regions="">
                                    <div className="containerv2__region containerv2__region--12" data-region="">
                                        <div id="6a4d4059-d40e-4eca-bc92-bfa4163244b6">
                                            <button type="button" className="button button--align-left age-gate__btn--confirm c-bg c-bg--primary c-tx c-tx--white" onClick={()=> is21()}>yes</button>
                                        </div>
                                        <div id="fa5ad878-0897-4905-a861-2707a1021e80">
                                                <button type="button" className="button button--align-left age-gate__btn--deny c-bg c-bg--primary c-tx c-tx--white"   onClick={()=> notAllowed()}>no</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="containerv2__background" aria-hidden="true" data-container-background="" data-region="">
                                </div>
                            </div>
                                    
                        </div>
                    </div>
                    <div className="containerv2__background" aria-hidden="true" data-container-background="" data-region="">
                        <div id="SH9f87d49d-ad0d-410b-93e3-f6c26aa4a313" className="image image--full">
                            <img className="image__img" alt="" loading="lazy"  src={background} />
                        </div>      
                    </div>
                </div>
            </div>
            
        </>
    );
}
