
import './style.css';
import { Link } from 'react-router-dom';
import contactIcon from '../../assets/img/d3daf4d0-b0b2-4a9e-92e5-ab79838e0cb8.svg';
import React from 'react';
import Sticky from 'react-sticky-el';

const FloatingContact = () => {
   
    return (
        <Sticky mode="bottom" className="floating-contact-container">
            <Link className="link-region__link floating-contact" to="/contact-us/" target="" aria-label="Contact Us">

                <div id="9f53366a-21ee-45a5-85e6-387ff5271cfe" className="image image--contact-icon">

                    
                        <img className="image__img"
                            src={contactIcon}
                            alt="" loading="lazy" />
                    
                </div>
            </Link>
        </Sticky>
    )
}
  
export default FloatingContact
