
import '../../../assets/css/listgrid.css';
import './style.css';
import cogIcon from '../../../assets/img/cog.svg';
import browserIcon from '../../../assets/img/browser.svg';
import userIcon from '../../../assets/img/user.svg';
import truckIcon from '../../../assets/img/truck.svg';

const ListGrid = () => {
    return (
      <>
        <div id="ba9fda79-7591-49aa-b0ad-2545f645244e" className="containerv2" style={{padding: '50px 0'}}>
            <div className="containerv2__regions" data-container-regions style={{'maxWidth': '1320px'}}>
                <div className="containerv2__region containerv2__region--12" data-region>


                    <div id="44336132-5cd1-43ab-8946-a8f4c449438b"
                        className="listgrid shouse-homelist listgrid--icon-list listgrid--align-left listgrid--justify listgrid--justify-even listgrid--v-align-start"
                        data-carousel-items="1">
                        <ul className="listgrid__list">
                            <li className="listgrid__item">

                                <div className="listgrid__header">
                                    <div className="listgrid__icon">
                                        <img src={cogIcon} className="icon" alt="cog icon"/>
                                    </div>
                                    <div className="listgrid__title"><span>Manufacturing</span></div>
                                </div>
                                

                                <div className="text-content">
                                    <div className="listgrid__description">
                                        <div className="paragraph">
                                            Combined 80,000 sqft of GMP production space. State-of-the-art equipment &amp;
                                            processes to manufacture over 700+ unique SKUs in all product types.
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li className="listgrid__item">

                                <div className="listgrid__header">
                                    <div className="listgrid__icon">
                                        <img src={browserIcon} className="icon" alt="cog icon"/>
                                    </div>
                                    <div className="listgrid__title"><span>Technology</span> <span>Suite</span></div>
                                </div>
                                <div className="text-content">
                                    
                                    <div className="listgrid__description">
                                        <div className="paragraph">
                                            Intuitive dispensary eCommerce platform.
                                            Real-time data analytics for our brand partners.
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li className="listgrid__item">

                                <div className="listgrid__header">
                                    <div className="listgrid__icon">
                                        <img src={userIcon} className="icon" alt="cog icon"/>    
                                    </div>
                                    <div className="listgrid__title"><span>Sales</span> <span>and</span> <span>Marketing</span></div>
                                </div>
                                <div className="text-content">
                                    
                                    <div className="listgrid__description">
                                        <div className="paragraph">
                                            Robust marketing and sales team which coordinates with our brand and dispensary
                                            partners in each market.
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li className="listgrid__item">
                                <div className="listgrid__header">

                                    <div className="listgrid__icon">
                                        <img src={truckIcon} className="icon" alt="cog icon"/>
                                    </div>
                                    <div className="listgrid__title"><span>Logistics</span> <span>&</span> <span>Distribution</span></div>
                                </div>
                                <div className="text-content">
                                    
                                    <div className="listgrid__description">
                                        <div className="paragraph">
                                            We deliver to over 1000 unique dispensaries a month.
                                            GPS Routed &amp; tracked transportation vehicles.
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>  
            </div>
        </div>
      </>
    )
}
 
export default ListGrid
