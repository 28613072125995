import { useEffect} from 'react';
import YouTube from "react-youtube";

import './style.css';


const YTFullScreenBackgroundVideo = (props) => {
    let videoID = props.videoID
    let h1Tag = props.h1Tag;
    let pTag = props.pTag;
    
    const onPlayerReady = (event) => {
        const player = event.target;
        var videoPlayer = document.querySelector('.containerv2--bg-slide-left');
        if (videoPlayer) {
            videoPlayer.classList.add('containerv2--bg-sliding-left');
        }
        player.pauseVideo();
      };
    
      const onPlayerStateChange = (event) => {
        const player = event.target;
        player.playVideo();
      };
    
      const options = {
        height: "",
        width: "",
        playerVars: {
            'autoplay': 1,
            'disablekb': 1,
            'showinfo': 0,
            'autohide': 1,
            'loop': 1,
            'controls': 0,
            'modestbranding': 1,
            'rel': 0,
            'enablejsapi': 1,

        },
      };
    
    useEffect(() => {
        
        var videoPlayer = document.querySelector('.containerv2--bg-slide-left');
            if (videoPlayer) {
                videoPlayer.classList.add('containerv2--bg-sliding-left');
            }
    }, []);

    
    return (
      <>
       
        <div id="SHYT996f5d02-6abf-4ea5-9496-89c692295343" className="containerv2 containerv2--bg-slide-left containerv2--middle containerv2--masthead c-tx c-tx--white">
            <YouTube className="ytvideowrapper" 
                videoId={videoID}
                opts={options}
                onReady={onPlayerReady}
                onStateChange={onPlayerStateChange}
            />
            <div className="overlay"></div>
        </div>
      </>
    )
}
 
export default YTFullScreenBackgroundVideo
