import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import FloatingContact from '../../layout/FloatingContact';

import FullScreenBackgroundVideo from '../../components/FullScreenBackgroundVideo';
import ListGrid from './ListGrid';
import CallToAction from './CallToAction';
import CounterUp from './CounterUp';
import Gallery from './Gallery';

import './style.css';

export function TechnologyDispensaryPage() {
  return (
    <>
        <Header/>
        
        <div className="App">
            <FullScreenBackgroundVideo h1Tag="" pTag="" iFrameSrc="https://player.vimeo.com/video/742728307?background=1?rel=0&autoplay=1&muted=1&loop=1"/>
            <ListGrid />
            <CallToAction />
            
            <CounterUp />
            <Gallery />
            <FloatingContact />
        </div>
        
        <Footer/>
    </>
  );
}
