import backgroundImage from '../../../assets/img/f92f2d15-3051-4c57-b8af-5ec61f2e9ac8.jpg'
import './style.css';


const callToAction = () => {
    return (
      <>
        <div id="dbab8c50-c6a7-4f43-bc78-e4ed66b3efe0" className="containerv2 containerv2--inl containerv2--inl-50 containerv2--gutter c-bg c-bg--black">
          <div className="containerv2__regions" >
              <div className="containerv2__region containerv2__region--12" data-region>

                  <div id="cb8603d8-7db5-4fdc-aa9f-7123637cff55" className="text-content">
                      <div className="text-content__content" data-wysiwyg>
                          <h1>OUR PROCESS</h1>
                          <p className="text-center text-md-left">Frequent delivery options <span className="color-primary d-sm-none">|</span> No transportation fees ever</p>
                          <p className="mb-sm-1">
                            <a href="https://dashboards.stashhousedistro.com" target="_blank" rel="noreferrer" className="button button--align-left c-bg c-bg--primary c-tx c-tx--white mx-sm-auto">Dispensary Login</a>
                          </p>
                          
                      </div>
                  </div>
                  <div id="8b5a334f-344d-48e0-bea4-6a2d04bbdce7" className="text-content">
                      <div className="text-content__content" >
                          <p>With our ease of ordering platform and frequent delivery options, simply choose the products your patients love and select the most convenient delivery option. No transportation fees, ever.</p>
                          <p>When it comes to orders and delivery, let us do the heavy lifting so you can care for what matters, your patients.</p>
                          <p>From <strong>ingredient selection</strong> to the <strong>final development</strong> of a specific project, our strict adherence to quality SOPs guarantees a consistent, top-quality product in every batch.</p>
                      </div>
                  </div>
              </div>

          </div>
          <div className="containerv2__background" aria-hidden="true">

              <div id="8ec43413-a58b-4136-8904-264a0b926128" className="image">

                      <img className="image__img"
                          src={backgroundImage}
                          alt="Background" loading="lazy" />
                
              </div>
          </div>
      </div>
      </>
    )
}
 
export default callToAction
