import React, { useEffect, useRef, } from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';


import  '../../../assets/css/gallery.css'
import './style.css';

import slide1 from '../../../assets/img/4ca4ce95-23a6-4528-87fb-fbdd6bc3c6f3.jpg';
import slide2 from '../../../assets/img/338e3ca9-2516-481c-b46f-f400bfae3250.jpg';
import slide3 from '../../../assets/img/7884d292-d33b-4401-8a5c-97ca33a87b9c.jpg';

const Gallery = () => {
    const myGallery = useRef(null);
   
    const properties = {
        duration: 5000,
        autoplay: true,
        transitionDuration: 500,
        arrows: false,
        infinite: true,
        easing: "ease",
        //indicators: (i) => <div className="indicator">{i + 1}</div>
    };
    const divStyle = {
        display: 'flex',
        aspectRatio: '5/3',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: 'auto'
    }
    const slideImages = [
        {
          url: slide1,
          caption: 'Slide 1'
        },
        {
          url: slide2,
          caption: 'Slide 2'
        },
        {
          url: slide3,
          caption: 'Slide 3'
        },
    ];
  
    function back(){
        myGallery.current.goBack();
       
    }
    function next(){
        myGallery.current.goNext();
    }
    useEffect(() => {
       
    }, []);
    return (
      <>
        <div id="SH12e742cd-abd4-448c-9817-464958cde8d2" className="containerv2 containerv2--middle containerv2--gutter c-bg c-bg--dark">
            <div className="containerv2__regions" data-container-regions>
                <div className="containerv2__region containerv2__region--8" data-region>
                    <div className="slide-container">
                        <Slide ref={myGallery} {...properties}>
                            {slideImages.map((slideImage, index)=> (
                                <div key={index}>
                                    <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                    </div>
                                </div>
                            ))} 
                        </Slide>
                        <div className="stashhouse-slideshow__navigation">
                            <button className="sxx-slideshow__navigation-prev"  onClick={()=> back()}>
                                <RiArrowLeftSLine className="gallery-prev"/>
                            </button>
                            <button className="sxx-slideshow__navigation-next"  onClick={()=> next()}>
                                <RiArrowRightSLine className="gallery-next"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="containerv2__region containerv2__region--4" data-region>

                    <div id="cbfd25d0-53ab-4e70-a96f-fd23afe16ab4" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <h1>A Look into The Portal</h1>
                        </div>
                    </div>
                    <div id="f5bc9127-20a1-44fc-b1f5-a27f6ffab5f0" className="text-content">
                        <div className="text-content__content" data-wysiwyg>
                            <p className="text-center text-md-left">Every Cannabis Format Under One Roof <br /><br />-700+ SKU's<br /><br />-New Product Releases<br /><br />-Hot
                                Deals<br /><br />-Easy Lab Access<br /><br />-No Delivery Fees</p>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
      </>
    )
}
 
export default Gallery
