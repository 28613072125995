import { useEffect} from 'react';

import { RiCloseCircleLine } from 'react-icons/ri';
import logo from '../../assets/img/a5607a2a-0ee1-4f1e-8315-ca59b40c5dcb.png';
import './style.css';
import '../../assets/js/navigation.js';
import { Link } from 'react-router-dom';

import React from 'react';

const Header = () => {
    

    function botCheck() {
        var botPattern = "(googlebot\/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|APIs-Google|AdsBot-Google-Mobile|AdsBot-Google|Googlebot|Chrome-Lighthouse|bingbot|facebookexternalhit|DuckDuckBot|Facebot|Twitterbot|Applebot|TweetmemeBot)";
        var re = new RegExp(botPattern, 'i');
        var userAgent = navigator.userAgent;
        if (re.test(userAgent)) {
            return true;
        } else {
            return false;
        }
    }

   
    function openNav() {
        //if (menu) {
            document.body.classList.add("header-side-nav-open");
            //window.a11y.trapFocus(menu);
        //}
    }
    function closeNav() {
        //if (menu) {
            document.body.classList.remove("header-side-nav-open");
        //    window.a11y.untrapFocus();
        //}
    }
    
    useEffect(() => {
        
            if (window.sessionStorage.is21+'' !== 'true'){
                //window.location.href = "/age-gate";
                
            }
       
       // window.addEventListener('load', (event) => {
            var droppers = document.querySelectorAll(
                '.mobile--only button.navigation__link');
            droppers.forEach(function (val, key) {
                var dropdown = val.nextElementSibling;
                if (dropdown.classList.contains('navigation__list')) {
                    val.addEventListener('click', function (e) {
                        dropdown.classList.toggle(
                            'navigation__list--expanded');
                    });
                }
            });
            var linkRegions = document.querySelectorAll('.link-region__link');
            linkRegions.forEach(function (val, key) {
                var label = val.ariaLabel;
                if (label) {
                    val.setAttribute('title', label);
                }
            });
       // });
        
    }, []);
    return (
        <>
            <nav className="skip-links" title="Accessibility">
                <div className="skip-links__links">
                    <a href="#main">Skip to Main Content</a>
                    <a href="#footer">Skip to Footer</a>
                </div>
            </nav>
            <header id="bbc64c91-f511-4a09-97f1-095b363511d3" className="containerv2 containerv2--p-sticky containerv2--grav-top containerv2--inl containerv2--inl-auto containerv2--middle c-bg c-bg--dark">
                <div className="containerv2__regions" data-container-regions>
            
                <div className="containerv2__region containerv2__region--12" data-region>


                    <div id="732220fc-28c1-4ca4-8e36-bcb49c083776" className="link-region">
                        <Link to="/"  className="link-region__link">

                            <div id="fc5cba0e-505b-4d82-80a5-a4cfc6a85d61" className="logo">
                                <img src={logo}
                                    className="logo__img" alt="Stash House Corporate" />
                            </div>
                        </Link>
                    </div>
                
                    <nav id="269b2599-dda7-4fba-899a-19861b82e8e2" className="navigation desktop--only" aria-label="Main">
                        <button className="navigation__menu" aria-label="Open main navigation" aria-haspopup="true" onClick={()=> openNav()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="navigation__container">
                            <div className="navigation__action">
                                <button className="navigation__close" aria-label="Close main navigation"  onClick={()=> closeNav()}>
                                    <RiCloseCircleLine className="App-close"/>
                                    
                                </button>
                            </div>
                            <div className="navigation__content">
                                <ul className="navigation__list">
                                    <li className="navigation__item">
                                        <button className="navigation__link" aria-haspopup="true">Technology Suite</button>
                                        <ul className="navigation__list">
                                            <li className="navigation__item">
                                                <Link to="/technology-suite-dispensary/" className="navigation__link" >
                                                Dispensaries
                                                </Link>
                                            
                                            </li>
                                            <li className="navigation__item">
                                            <Link to="/technology-suite-brands/" className="navigation__link">
                                               Brands
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="navigation__item">
                                        <button className="navigation__link" aria-haspopup="true">Locations</button>
                                        <ul className="navigation__list">
                                           
                                            <li className="navigation__item">
                                                <Link to="/mississippi" className="navigation__link">Mississippi</Link>
                                            </li>
                                            <li className="navigation__item">
                                                <Link to="/missouri/" className="navigation__link">Missouri</Link>
                                            </li>
                                            <li className="navigation__item">
                                                <Link to="/new-mexico/" className="navigation__link">New Mexico</Link>
                                            </li>
                                            <li className="navigation__item">
                                                <Link to="/oklahoma/" className="navigation__link">Oklahoma</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="navigation__item">
                                        <Link to="/manufacturing/" className="navigation__link">Manufacturing</Link>
                                    </li>
                                    <li className="navigation__item">
                                        <Link to="/distribution/" className="navigation__link">Distribution</Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>


                    <div id="949fab88-85b5-4941-a1d7-372ebfddd566">
                        <a href="https://dashboards.stashhousedistro.com" target="_blank" rel="noreferrer"
                            className="button button--align-left desktop--only c-bg c-bg--dark">Brand Login</a>
                    </div>



                    <div id="e279e8e6-7c33-4aec-a96f-56e87b6835fc">
                    <Link to="/register/" target=""
                            className="button button--align-left desktop--only c-bg c-bg--dark">Dispensary Login</Link>
                    </div>

                    <nav id="c5e441db-8a74-4c85-9e6e-e8e6717d7971" className="navigation mobile--only" aria-label="Main">
                        <button className="navigation__menu" aria-label="Open main navigation" aria-haspopup="true" onClick={()=> openNav()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="navigation__container">
                            <div className="navigation__action">
                                <button className="navigation__close" aria-label="Close main navigation"  onClick={()=> closeNav()}>
                                <RiCloseCircleLine className='App-close'/>
                                </button>
                            </div>
                            <div className="navigation__content">
                                <ul className="navigation__list">
                                    <li className="navigation__item">
                                        <a href="/register/" className="navigation__link">Dispensary Login</a>
                                    </li>
                                    <li className="navigation__item">
                                        <a href="https://dashboards.spherexx.com/stashhouse/Account/"
                                            className="navigation__link" target="_blank" rel="noreferrer">Brands Login</a>
                                    </li>
                                    <li className="navigation__item">
                                        <button className="navigation__link" aria-haspopup="true">Technology Suite</button>
                                        <ul className="navigation__list">
                                            <li className="navigation__item">
                                            <Link to="/technology-suite-dispensary/" className="navigation__link" onClick={()=> closeNav()}>
                                                Technology Suite - Dispensary
                                                </Link>
                                            </li>
                                            <li className="navigation__item">
                                            <Link to="/technology-suite-brands/" className="navigation__link" onClick={()=> closeNav()}>
                                                Technology Suite - Brands
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="navigation__item">
                                        <button className="navigation__link" aria-haspopup="true">Locations</button>
                                        <ul className="navigation__list">
                                            <li className="navigation__item">
                                                <a href="/oklahoma/" className="navigation__link" onClick={()=> closeNav()}>Oklahoma</a>
                                            </li>
                                            <li className="navigation__item">
                                                <a href="/new-mexico/" className="navigation__link" onClick={()=> closeNav()}>New Mexico</a>
                                            </li>
                                            <li className="navigation__item">
                                                <a href="/missouri/" className="navigation__link" onClick={()=> closeNav()}>Missouri</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="navigation__item">
                                        <Link to="/manufacturing/" className="navigation__link" onClick={()=> closeNav()}>Manufacturing</Link>
                                    </li>
                                    <li className="navigation__item">
                                        <Link to="/distribution/" className="navigation__link" onClick={()=> closeNav()}>Distribution</Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                    <nav id="c311df1f-54ed-4134-94fa-e737920cd7d5" className="navigation desktop--only" aria-label="Main">
                        <button className="navigation__menu" aria-label="Open main navigation" aria-haspopup="true" onClick={()=> openNav()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="navigation__container">
                            <div className="navigation__action">
                                <button className="navigation__close" aria-label="Close main navigation" onClick={()=> closeNav()}>
                                <RiCloseCircleLine className="App-close"/>
                                </button>
                            </div>
                            <div className="navigation__content">
                                <ul className="navigation__list">

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

            
                </div>   
            </header>
        </>
    )
  }
  
export default Header
